<template>
  <TTView>
    <VRow>
      <VCol>
        <CompanyCard :company="company" />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { DEFAULT_COMPANY } from '../../../components/accounts/companies/common';
import CompanyCard from '../../../components/accounts/companies/CompanyCard.vue';

export default {
  name: 'SysCompanyItem',

  components: {
    CompanyCard,
  },

  data() {
    return {
      company: { ...DEFAULT_COMPANY },
    };
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;
        const { id } = this.$route.params;
        const data = { id };
        const companyResponse = await this.$di.api.Account.getCompany(data);

        this.company = companyResponse.company;
      } catch (err) {
        this.$di.notify.errorHandler(err);
        this.$di.redirect.errorHandler404(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
